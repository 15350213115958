<template>
  <div class="catalog">
    <div class="item" v-for="(item, index) in list" :key="index">
      <div class="title flex-between">
        <div class="over-hidden1">{{item.PlatCatName}}</div>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="str over-hidden1" v-if="item.LeafPlatCategoryList.length > 0">
        <span v-for="(e, i) in item.LeafPlatCategoryList" :key="i" @click="childToDetail(e.PlatCatName, e.PlatCatCode)">{{e.PlatCatName}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {getCategory} from "@/api/home"
export default {
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getCatalog()
  },
  methods: {
    async getCatalog() {
      let res = await getCategory({
        StartPlatCatCode: 0,
        _pagesize: 0,
        _pageIndex: 1
      })
      if(res.IsSuccess) {
        this.list = res.Data
      }
    },
    async parentToDetail(name,id) {
      this.$router.push({
        name: "Result",
        query: {
          ParentPlatCatId: id,
          name: name
        }
      })
    },
    async childToDetail(name, code) {
      this.$router.push({
        name: "Result",
        query: {
          PlatCatCode: code,
          name: name,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.catalog {
  width: 100%;
  padding-top: 10px;
  .item {
    width: 100%;
    // height: 69px;
    padding: 0 12px;
    margin-bottom: 20px;
    cursor: pointer;
    .title {
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #1A3664;
      >div {
        max-width: 192px;
        // &:hover {
        //   text-decoration: underline;
        // }
      }
    }
    .str {
      width: 100%;
      height: 18px;
      font-size: 12px;
      line-height: 18px;
      color: #585858;
      margin-top: 10px;
      span {
        margin-right: 12px;
        &:hover {
          color: #FE0036;
        }
      }
    }
  }
}
</style>