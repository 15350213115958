<template>
  <div class="booklist">
      <div class="booklist-title">{{title}}</div>
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index" @click="toDetail(item.ItemId)">
          <div class="cover center">
            <img :src="item.MainPic" alt="">
          </div>
          <div class="des over-hidden2">[{{item.SkuList[0].GoodsSource}}] {{item.Title}}</div>
          <div class="price center">
            <Price :price="item.SkuList[0].SalePrice"/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Price from "@/components/price"
export default {
  name: "booklist",
  props: {
    list: {
      default: []
    },
    title: {
      default: "新书推荐"
    }
  },
  components: {
    Price
  },
  methods: {
    toDetail(id) {
      this.$router.push({
        name: "Detail",
        query: {
          id: id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.booklist {
    width: 100%;
    margin-top: 20px;
    .booklist-title {
      width: 100%;
      height: 50px;
      font-size: 24px;
      font-weight: 500;
      color: #1A3664;
      text-align: center;
      line-height: 50px;
    }
    .list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 178px;
        height: 265px;
        background: #FFFFFF;
        border-radius: 5px;
        margin-top: 10px;
        .cover {
          width: 100%;
          height: 178px;
          img {
            max-width: 160px;
            max-height: 160px;
          }
        }
        .des {
          width: 100%;
          height: 40px;
          padding: 0 36px;
          font-size: 14px;
          line-height: 20px;
          color: #1A3664;
          word-break: break-all;
        }
      }
    }
  }
</style>