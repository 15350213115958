<template>
  <div class="home">
    <div class="head flex-between">
      <div class="left">
        <Catalog />
      </div>
      <div class="right">
        <div class="banner">
          <el-carousel :interval="5000" height="458px">
            <el-carousel-item>
              <img src="@/static/swiper1.jpg" alt="" width="100%" height="458px">
            </el-carousel-item>
            <el-carousel-item>
              <img src="@/static/swiper2.jpg" alt="" width="100%" height="458px">
            </el-carousel-item>
            <el-carousel-item>
              <img src="@/static/swiper3.png" alt="" width="100%" height="458px">
            </el-carousel-item>
          </el-carousel>
        </div>
        <Booklist title="新书推荐" :list="newBooks"/>
        <Booklist title="每日精选" :list="dayBooks"/>
      </div>
    </div>
    <div class="common-list">
      <div class="common-list-title">为你推荐</div>
      <div class="list">
        <div class="item" v-for="(item, index) in likeList" :key="index" @click="toDetail(item.ItemId)">
          <div class="cover center">
            <img :src="item.MainPic" alt="">
          </div>
          <div class="des over-hidden2">[{{item.SkuList[0].GoodsSource}}] {{item.Title}}</div>
          <div class="price center">
            <Price :price="item.SkuList[0].SalePrice"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getRecommend} from "@/api/home"
import Price from "@/components/price"
import Booklist from "@/components/booklist"
import Catalog from "./catalog.vue"
export default {
  name: 'Home',
  components: {
    Price,
    Booklist,
    Catalog
  },
  data() {
    return {
      newBooks: [],
      dayBooks: [],
      likeList: [],
    }
  },
  mounted() {
    this.getNewBooks()
    this.getDayBooks()
    this.getList()
  },
  methods: {
    // 新书推荐
    async getNewBooks() {
      let res = await getRecommend({
        SmartCode: "new-book",
        _PageIndex: 1,
        _PageSize: 15
      });
      if(res.IsSuccess) {
        this.newBooks = res.Data
      }
    },
    // 每日精选
    async getDayBooks() {
      let res = await getRecommend({
        SmartCode: "guess-like",
        _PageIndex: 1,
        _PageSize: 15
      });
      if(res.IsSuccess) {
        this.dayBooks = res.Data
      }
    },
    // 为你推荐
    async getList() {
      let res = await getRecommend({
        SmartCode: "guess-like",
        _PageIndex: 1,
        _PageSize: 20
      });
      if(res.IsSuccess) {
        this.likeList = res.Data
      }
    },
    toDetail(id) {
      this.$router.push({
        name: "Detail",
        query: {
          id: id
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 1200px;
  margin: 0 auto;
  .head {
    width: 100%;
    .left {
      width: 241px;
      // height: 1377px;
      background: #FFFFFF;
    }
    .right {
      width: 930px;
      // height: 1377px;
      .banner {
        width: 930px;
        height: 458px;

      }
      .new-book {
        width: 100%;
      }
    }
  }
  .common-list {
    width: 100%;
    margin-bottom: 80px;
    .common-list-title {
      width: 100%;
      height: 70px;
      font-size: 24px;
      font-weight: 500;
      color: #1A3664;
      text-align: center;
      line-height: 70px;
    }
    .list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 228px;
        height: 265px;
        background: #FFFFFF;
        border-radius: 5px;
        margin-bottom: 10px;
        .cover {
          width: 100%;
          height: 190px;
          img {
            max-width: 160px;
            max-height: 160px;
          }
        }
        .des {
          width: 100%;
          height: 40px;
          padding: 0 36px;
          font-size: 14px;
          line-height: 20px;
          color: #1A3664;
          word-break: break-all;
        }
      }
    }
  }
}
</style>