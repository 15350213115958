import {post} from '@/utils/http.js';

// 获取推荐数据
// 必填SmartCode
// 猜你喜欢传：guess-like
// 相关推荐传：related
// 新书推荐传：new-book
// ItemId 商品ID 相关推荐时必填
export function getRecommend(params) {
  return post('/ItemImpApi/GetEsItemList', params);
}

// 获取分类
export function getCategory(params) {
  return post('/PlatCategoryImpApi/GetPlatCategoryList', params);
}